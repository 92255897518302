import React from 'react'

import HeaderImage from 'components/HeaderImage'
import Recommended from 'components/Recommended'
import EmailSignup from 'components/EmailSignup'
import DeliveryBanner from 'components/DeliveryBanner'
import Collection from 'components/shared/Collection'
import Spacer from 'components/shared/Spacer'

export default ({data}) => {
	return (
		<div style={{overflowX:'hidden'}}>
			<HeaderImage
				title={data.header.title}
				subSection={data.header.copy}
				image={data.header.image}/>
			<Spacer />
			<Recommended 
		        header={data.recommended.title}
		        data={data.recommended.items}
		        cta={data.recommended.cta}/> 
			{data.sections.map((section, i)=> 
				<div>
				<Spacer />
				<HeaderImage
					collection={
						<Collection section={section} />
					}
					reverse={i%2===0}
					image={section.image} />
				
				</div>
			)}
			<EmailSignup />
			<DeliveryBanner />
		</div>
	)

}