import React from 'react'

import ProductListingItem from 'components/ProductListing/ProductListingItem'
import styles from './Recommended.module.scss'
import Button from 'components/shared/Button'

export default ({data, header,subheader, cta }) => {
	if (!data) {
		return <div/>
	}

	return (
		<div className={styles.container}>
			<h2 className={styles.header}>{header}</h2>
			<h4 className={styles.subheader}>{subheader}</h4>
			<div className={styles.gallery}>
				{
					data.map((item, i) =>
					<ProductListingItem product={item} key={i} />
					)
				}
			</div>
			<div className={styles.ctaContainer}>
				<Button big to={cta.link}>{cta.title}</Button>
			</div>
		</div>
	)
}
	
