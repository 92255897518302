import React, { useContext } from 'react'

import styles from './Collection.module.scss'
import Slides from './Slides'
import Button from 'components/shared/Button'
import { CartContext } from 'context/CartProvider'

export default ({section}) => {
	const { add } = useContext(CartContext)

	const addToBag = () => {
		section.items.map((item)=>{
			add(item.skus[0].id,1)
		})
	}
	return (
		<>
			<h2 className={styles.header}>{section.title}</h2>
			<Slides data={section.items}/>
			<Button big center onClick={()=>addToBag()}>Add Collection to Cart</Button>
		</>
	)
}