import React from 'react'

import { ContactForm } from 'components/shared/Forms'
import styles from './EmailSignup.module.scss'

export default () => (
  <section className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.text}>
            <h3 style={{color:'#15AE74'}}>The Bloomed Newsletter</h3>
            <p className={styles.copy} style={{color:'#11103E'}}>Sign up for succulent reads</p>
        </div>
        <ContactForm className="footer-form"/>
      </div>
  </section>
)
