import React from 'react'

import { BikeIcon } from 'components/shared/Icons'
import plantWall from '../../../static/images/plant-wall.png'
import MaxWidthCard from 'components/shared/MaxWidthCard'

const Copy = () => (
	<div style={{display:'inline-flex',alignItems:'center'}}>
		<BikeIcon height={30} color='#15ae74'/>
		<div style={{marginLeft:10,color:'#495057',fontSize:'1rem',fontWeight:700}}>Free delivery on orders over $50</div>
	</div>
)

export default (props) => (
	<MaxWidthCard 
		// image={plantWall}
		copy={<Copy/>}
		header="Free delivery on orders over $50"/>
)