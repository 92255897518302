import React from 'react'
import Slider from 'react-slick'

import ProductListingItem from 'components/ProductListing/ProductListingItem'
import { ChevronRight , ChevronLeft } from 'components/shared/Icons'
import './Slides.scss'
/* eslint-disable react/prop-types */
const Arrow = ({ currentSlide, slideCount, type, ...props }) => (
  <button {...props}>
    {type === 'prev' ? <ChevronLeft className="icon"/> : <ChevronRight className="icon"/>}
  </button>
);
/* eslint-enable */


export default class Slides extends React.Component {
	constructor(props) {
	    super(props);
	}

	beforeChangeHandler(_, nextIndex) {
	    // We can't pass this as prop as React Slick bugs
	    // on re-render: https://github.com/akiran/react-slick/issues/136
	    this.sliderTabs.setState({ activeTab: nextIndex });
	}

	render(){
		const { data } = this.props
		const length = data.length < 3 ? data.length : 3
		const settings = {
	      accessibility: false,
	      dots: true,
	      draggable: true,
	      slidesToShow: length,
	      slidesToScroll: 3,
	      nextArrow: <Arrow type="next" />,
	      prevArrow: <Arrow type="prev" />,
	      responsive: [
	        {
	          breakpoint: 600,
	          settings: {
	            slidesToShow: 2,
	            slidesToScroll: 2,
	            infinite: true,
	            dots: true
	          }
	        },
	        {
	          breakpoint: 480,
	          settings: {
	            slidesToShow: 1,
	            slidesToScroll: 1
	          }
	        }
	        ]
	    };

		return (
			<div className="collection-slides">
				 <div
			        className="slideshow"
			        ref={ref => {
			          this.container = ref;
			        }}
			      >
			      	<Slider
			            {...settings}
			            ref={ref => {
			              this.slider = ref;
			            }}
			          >
			          	{data.map((item) =>
			             <ProductListingItem
			             	product={item} 
			             	key={item.slug}
			             	slider={true}
			             	hideCart />
			          	)}
			        </Slider>
			      </div>
			</div>
		)	
	}
}




