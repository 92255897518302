
export function formatNodes(data) {
   return data.group.map((groups) => 
    {
      let variants = groups.edges.map((node)=> {
        return {
          id: node.node.id,
          attributes: node.node.attributes,
          price: node.node.price
        }
      })
      let hasVariants = false
      if (variants.length > 1) {
        hasVariants = true 
      }
      let price = groups.edges[0].node.price
      let slug = groups.edges[0].node.product.id
      let metadata = groups.edges[0].node.product.metadata
      let images = groups.edges[0].node.product.images
      let name = groups.edges[0].node.product.name
      return {
        slug,
        skus: variants,
        metadata,
        images, 
        name,
        price,
        hasVariants
      }
    }
  )
}