import React from 'react'

import styles from './MaxWidthCard.module.scss'

export default ({image,header,copy}) => (
	<section className={styles.container} css={{
backgroundImage: image&&`linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, .8)), url(${image})`}}>
		<div className={styles.cardContainer}>
			<div className={styles.copy}>
				{copy? copy: 
					<div className={styles.copyHeader}>
						<h2>{header}</h2>
					</div>
				}
			</div>
		</div>
	</section>
)